import React, { Fragment } from 'react';
import styled from 'styled-components';
import { v4 as uuidv4 } from 'uuid';
import { Typography } from 'antd';

import Link from '../Link';
import Table from '../Table';

const { Title, Paragraph, Text } = Typography;

const UL = styled.ul`
  margin-top: -1rem;
`;

const Li = styled.li``;
const tagsMapped = {
  p: Paragraph,
  strong: Text,
  em: Text,
  ul: UL,
  li: Li,
  a: Link,
};

const flatDeep = (arr, d = 1) => {
  return d > 0
    ? arr.reduce(
        (acc, val) =>
          acc.concat(Array.isArray(val) ? flatDeep(val, d - 1) : val),
        []
      )
    : arr.slice();
};
// This is done to keep consistency with the table component deing used in different places.
// Otherwise tagsMapped can be used. just add the below (move styled.[tag] into a variable.)
// {
//   table: styled.table``,
//   thead: styled.thead``,
//   tr: styled.tr``,
//   th: styled.th``,
//   tbody: styled.tbody``
//   td: td.styled``
// }
const getTable = tableChildren => {
  let columnHeadings = [];
  let dataRows = [];

  const getValues = rowChildren => {
    return rowChildren.map(column => {
      if (column.children) return getValues(column.children);
      return column.value;
    });
  };

  tableChildren.forEach(element => {
    if (element.tagName === 'thead') {
      columnHeadings = flatDeep(getValues(element.children), Infinity);
      return;
    }

    dataRows = getValues(element.children).map(row => {
      return flatDeep(row, Infinity);
    });
  });

  return (
    <Table
      key={uuidv4()}
      tableInformation={{
        headings: columnHeadings,
        rows: dataRows,
      }}
    />
  );
};

const MarkdownHTMLAst = ({ data }) => {
  const getChildren = children => {
    return children.map(item => {
      if (item.tagName === 'table') {
        return getTable(item.children);
      }
      if (item.children) {
        const level = tagsMapped[item.tagName] || Number(item.tagName.slice(1));
        const ComponentTag = tagsMapped[item.tagName] || Title;

        return (
          <ComponentTag
            {...(ComponentTag === Title ? { level } : {})}
            {...(ComponentTag === Text && item.tagName === 'strong'
              ? { strong: true }
              : {})}
            {...(ComponentTag === Link ? { to: item.properties.href } : {})}
            key={uuidv4()}
          >
            {getChildren(item.children)}
          </ComponentTag>
        );
      }
      return <Fragment key={uuidv4()}>{item.value}</Fragment>;
    });
  };

  return <>{getChildren(data)}</>;
};

export default MarkdownHTMLAst;
