import React from 'react';
import { v4 as uuidv4 } from 'uuid';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const StyledTable = styled.table`
  border-collapse: collapse;
  th {
    text-align: center;
    background-color: ${props => props.theme.colors.grey.transparentLight}
  }

  th, td {
    border: 0.1rem solid black;
    padding: 0.5rem;
    font-size: 1.6rem;
  }
`;


const Table = ({tableInformation}) => {
  return (
    <StyledTable>
      <thead>
        <tr>
          {tableInformation.headings.map((item) => {
            return <th key={uuidv4()}>{item}</th>
          })}
        </tr>
      </thead>
      <tbody>
        {tableInformation.rows.map((row) => {
          return (
            <tr key={uuidv4()}>
              {row.map((content) => {
                return <td key={uuidv4()}>{content}</td>
              })}
            </tr>
          )
        })}
      </tbody>
    </StyledTable>
  )
}

Table.propTypes = {
  tableInformation: PropTypes.shape({
    headings: PropTypes.array.isRequired,
    rows: PropTypes.arrayOf(PropTypes.array.isRequired).isRequired
  })
}

// e.g. 
// const tableInformation = {
//   headings: ['Who else sets these cookies?', 'For what purpose?'],
//   rows: [
//     ['Google (Recaptcha)', "To validate that the interaction with our site is being performed by a human rather than an automated system or 'bot' for security purposes"],
//     ['test of anotehr row', 'reason']
//   ]
// }

export default Table;